/* eslint-disable react/jsx-props-no-spreading */
import { CloudUploadOutlined, EditOutlined, LoadingOutlined, TagOutlined } from '@ant-design/icons'
import { Button, List, Tooltip } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import * as assetsSlice from '../assetsSlice'
import CategoriesHelp from './categories-help copy/CategoriesHelp'
import styles from './CategoriesPanel.module.scss'
import { Category as Category1, Category } from '../types'

/**
 * Swap the order of item from one index to another
 * @param list list of categories
 * @param startIndex index of item to swap
 * @param endIndex index of item to swap with
 * @returns list of categories with the given indices swapped
 */
const reorder = (list: Category1[], startIndex: number, endIndex: number) => {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const CategoriesPanel: FunctionComponent = () => {
  const categories = useSelector(assetsSlice.selectCategoriesByOrder)
  const family = useSelector(assetsSlice.selectFamily)
  const category = useSelector(assetsSlice.selectCategory)
  const loading = useSelector(assetsSlice.selectLoadingCategories)
  const [categoriesList, setCategoriesList] = useState(categories)
  const [isHovered, setIsHovered] = useState<boolean[]>([])
  const { loadingCategoriesOrder } = useSelector((store: RootState) => store.assets)
  const autoTaggingAssets = useSelector(assetsSlice.selectAutoTaggingAssets)
  const dispatch = useDispatch()

  const updateCategories = async () => {
    dispatch(assetsSlice.updateCategoriesOrder(categoriesList))
  }

  useEffect(() => {
    setCategoriesList(categories)
  }, [categories])

  const openEditDrawer = (categoryEdit: Category) => {
    dispatch(
      assetsSlice.actions.toggleDrawer({ visible: true, type: 'category', data: categoryEdit })
    )
  }

  const openUploadCategoryAssetsDrawer = (selectedCategory: Category) => {
    dispatch(
      assetsSlice.actions.toggleDrawer({
        visible: true,
        type: 'upload-category-assets',
        data: selectedCategory,
      })
    )
  }

  const generateTagsForCategory = ({ hash }: Category) => {
    if (family) {
      dispatch(assetsSlice.generateTagsForCategory(hash, family?.productType))
    }
  }

  const handleHoverEnter = (index: number) => {
    setIsHovered((prevState) => ({ ...prevState, [index]: true }))
  }

  const handleHoverLeave = (index: number) => {
    setIsHovered((prevState) => ({ ...prevState, [index]: false }))
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result
    // means that the draggable was dropped outside the droppable area
    if (!destination) return

    // means that the draggable item was dropped on the same position
    if (source.index === destination.index && source.droppableId === destination.droppableId) return

    setCategoriesList((prevState) => reorder(prevState, source.index, destination.index))
  }

  if (!loading && !family) {
    return null
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Sider className={styles.root} width={250}>
        {loadingCategoriesOrder ? (
          <LoadingOutlined className={styles.loader} />
        ) : (
          <Droppable droppableId="tasks">
            {(droppableProvided) => (
              <div
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
                className={styles.menu}
              >
                <List
                  header={
                    <div className={styles.categoryHeader}>
                      <p className={styles.title}>Categories {loading && <LoadingOutlined />}</p>
                      <Button onClick={updateCategories}>Update</Button>
                    </div>
                  }
                  bordered
                  dataSource={categoriesList}
                  className={styles.root}
                  renderItem={(categoryFamily, index) => (
                    <Draggable
                      key={categoryFamily.id}
                      draggableId={categoryFamily.hash}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <div
                          onMouseLeave={() => handleHoverLeave(index)}
                          onMouseEnter={() => handleHoverEnter(index)}
                          onClick={() => {
                            dispatch(assetsSlice.getSubcategories(categoryFamily.hash))
                          }}
                          {...draggableProvided.draggableProps}
                          ref={draggableProvided.innerRef}
                        >
                          <div className="flex items-center pl-5 h-12">
                            <img
                              src="assets/menu-outline.svg"
                              className={styles.menuIcon}
                              alt=""
                              {...draggableProvided.dragHandleProps}
                            />
                            <span>{categoryFamily.name}</span>
                            {(category?.hash === categoryFamily.hash || isHovered[index]) && (
                              <>
                                <Button
                                  style={{ marginRight: '52px' }}
                                  size="small"
                                  shape="circle"
                                  className={styles.editButton}
                                  icon={<EditOutlined />}
                                  onClick={() => openEditDrawer(categoryFamily)}
                                />

                                <Tooltip title="Generate tags using AI" placement="right">
                                  <Button
                                    style={{ marginRight: '27px' }}
                                    size="small"
                                    shape="circle"
                                    className={styles.editButton}
                                    icon={<TagOutlined />}
                                    onClick={() => generateTagsForCategory(categoryFamily)}
                                    loading={autoTaggingAssets}
                                  />
                                </Tooltip>

                                <Tooltip
                                  title="Replace SVGs of multiple assets in this category"
                                  placement="right"
                                >
                                  <Button
                                    style={{ marginRight: '2px' }}
                                    size="small"
                                    shape="circle"
                                    className={styles.editButton}
                                    icon={<CloudUploadOutlined />}
                                    onClick={() => openUploadCategoryAssetsDrawer(categoryFamily)}
                                  />
                                </Tooltip>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )}
                />
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </Sider>
      {categories.length > 0 && <CategoriesHelp />}
    </DragDropContext>
  )
}

export default CategoriesPanel
