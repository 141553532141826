import { Col, Form, message, Row } from 'antd'
import Layout from 'antd/lib/layout/layout'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import useAxios from '../../../app/api/apiHook'
import handleError from '../../../app/api/handleError'
import * as usersSlice from '../usersSlice'
import UserDetailsAccountCard from './user-details-account-card/UserDetailsAccountCard'
import UserDetailsAccountMembersCard from './user-details-account-members-card/UserDetailsAccountMembersCard'
import UserDetailsHeader from './user-details-header/UserDetailsHeader'
import UserDetailsTwoColumns from './user-details-two-columns/UserDetailsTwoColumns'
import styles from './UserDetails.module.scss'
import UserDetailsAccountFamilyGroupPurchases from './user-details-account-family-group-purchases-card/UserDetailsAccountFamilyGroupPurchasesCard'
import { BundleStatusEnum } from '../../../app/common-interfaces/User'

export interface UserFormValues {
  status: string
  bundleStatus: BundleStatusEnum
  firstname: string
  lastname: string
  email: string
  stripeSubscriptionId: string
  stripeId: string
  isLegacy: boolean
  isBetaTester: boolean
  downloadsLimitEnabled: boolean
  publicApiEnabled: boolean
}

const UserDetails: FunctionComponent = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm<UserFormValues>()

  const params = useParams<{ userHash: string }>()
  const user = useSelector(usersSlice.selectUser)

  const [isSaving, setIsSaving] = useState(false)

  const [{ data, loading, error }, refetch] = useAxios(`/v4/users/${params.userHash}`, {
    manual: true,
  })

  const [, editUser] = useAxios(
    {
      url: `/v4/users/admin/${user?.hash}`,
      method: 'PATCH',
    },
    { manual: true }
  )

  const [, createUser] = useAxios(
    {
      url: '/v4/users/admin',
      method: 'POST',
    },
    { manual: true }
  )

  const onFinish = async (values: any) => {
    const {
      email,
      firstname,
      lastname,
      status,
      bundleStatus,
      stripeId,
      stripeSubscriptionId,
      isLegacy,
      isBetaTester,
      downloadsLimitEnabled,
      publicApiEnabled,
    } = values
    try {
      setIsSaving(true)
      if (user?.hash) {
        await editUser({
          data: {
            email,
            firstname,
            lastname,
            status,
            stripeId,
            stripeSubscriptionId,
            isLegacy,
            isBetaTester,
            downloadsLimitEnabled,
            publicApiEnabled,
          },
        })
      } else {
        await createUser({
          data: {
            email,
            firstname,
            lastname,
            status,
            bundleStatus,
            stripeId,
            stripeSubscriptionId,
            isLegacy,
            isBetaTester,
            downloadsLimitEnabled,
            publicApiEnabled,
          },
        })
      }
      setIsSaving(false)
      message.success('User saved successfully!')
    } catch (e) {
      setIsSaving(false)
      const responseError = handleError(e)
      message.error(`Something went wrong: ${responseError}`)
    }
  }

  useEffect(() => {
    if (data) {
      dispatch(usersSlice.actions.selectedUser(data))
    }
  }, [data, dispatch])

  useEffect(() => {
    if (params.userHash !== 'new') {
      refetch()
    } else {
      dispatch(usersSlice.actions.selectedUser(null))
    }
  }, [dispatch, params.userHash, refetch])

  useEffect(() => {
    if (!loading) {
      form.setFieldsValue({
        status: data?.status || '',
        bundleStatus: data?.bundleStatus || '',
        firstname: data?.firstname || '',
        lastname: data?.lastname || '',
        email: data?.email || '',
        stripeSubscriptionId: data?.stripeSubscriptionId || '',
        stripeId: data?.stripeId || '',
        isLegacy: data?.isLegacy || false,
        isBetaTester: data?.isBetaTester || false,
        downloadsLimitEnabled: data?.downloadsLimitEnabled || false,
        publicApiEnabled: data?.publicApiEnabled || false,
      })
    }
  }, [data, form, loading])

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error!</p>
  return (
    <Form
      form={form}
      name="user-details-form"
      onFinish={onFinish}
      layout="vertical"
      requiredMark="optional"
    >
      <Layout>
        <UserDetailsHeader isEditing={data} isSaving={isSaving} />
        <div className={styles.root}>
          <Row justify="center">
            <Col sm={24} md={20} lg={16} xl={14}>
              <UserDetailsTwoColumns
                leftCol={<UserDetailsAccountCard />}
                rightCol={
                  <>
                    <UserDetailsAccountMembersCard />
                    <UserDetailsAccountFamilyGroupPurchases />
                  </>
                }
              />
            </Col>
          </Row>
        </div>
      </Layout>
    </Form>
  )
}

export default UserDetails
