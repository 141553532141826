import { Form, message } from 'antd'
import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import useAxios from '../../../../app/api/apiHook'
import handleError from '../../../../app/api/handleError'
import DrawerTwoColumns from '../../../../components/DrawerTwoColumns/DrawerTwoColumns'
import * as assetsSlice from '../../assetsSlice'
import FamilyFormLeftColumn from './family-form-left-column/FamilyFormLeftColumn'
import FamilyFormRightColumn from './family-form-right-column/FamilyFormRightColumn'
import { Family } from '../../types'

interface FamilyFormProps {
  onSubmit(): void
  onSubmitSuccess(): void
  onSubmitError(): void
  family: Family
  form: any
}

const FamilyForm: FunctionComponent<FamilyFormProps> = ({
  onSubmit,
  onSubmitSuccess,
  onSubmitError,
  family,
  form,
}) => {
  const dispatch = useDispatch()

  const [, editFamily] = useAxios(
    {
      url: `/v4/families/${family?.hash}`,
      method: 'PUT',
    },
    { manual: true }
  )

  const [, createFamily] = useAxios(
    {
      url: '/v4/families',
      method: 'POST',
    },
    { manual: true }
  )

  const onFinish = async (formValues: any) => {
    onSubmit()
    const familyData = {
      ...formValues,
      cover: formValues.fileList
        ? formValues.fileList[formValues.fileList.length - 1].response.coverUrl
        : formValues.cover,
      extra: {
        is_beta: formValues.isBeta,
      },
      visibleOnlyOnFreebiesTab: formValues.visibleOnlyOnFreebiesTab || false,
    }
    try {
      let response
      if (family?.hash) {
        response = await editFamily({
          data: {
            ...familyData,
          },
        })
        dispatch(assetsSlice.actions.updateFamilyOnList(response.data))
      } else {
        response = await createFamily({
          data: {
            ...familyData,
          },
        })
        dispatch(assetsSlice.actions.addFamilyOnList(response.data))
      }
      /* We need to select the family again to be used case the user clicks on edit button again without changing the family on the select */
      dispatch(assetsSlice.actions.selectedFamily(response.data.hash))
      message.success('Family saved successfully!')

      onSubmitSuccess()
    } catch (error) {
      onSubmitError()
      const responseError = handleError(error)
      message.error(`Something went wrong: ${responseError}`)
    }
  }

  return (
    <Form
      form={form}
      name="family-form"
      onFinish={onFinish}
      layout="vertical"
      requiredMark="optional"
      initialValues={{
        name: family?.name,
        visible: family ? family.visible : false,
        order: family?.order || 1,
        defaultSize: family?.defaultSize || 100,
        isFree: family ? family.isFree : false,
        defaultLazyLoadItems: family?.defaultLazyLoadItems || 200,
        cover: family?.cover || '',
        isBeta: family ? family.extra.is_beta : false,
        description: family?.description,
        isNew: family ? family.isNew : false,
        onlyLegacyVisible: family ? family.onlyLegacyVisible : false,
        legacyHavePremium: family ? family.legacyHavePremium : false,
        oneColored: family ? family.oneColored : false,
        defaultSidebarSize: family?.defaultSidebarSize || 200,
        defaultDownloadSize: family?.defaultDownloadSize || 250,
        productType: family?.productType || 'icons',
        maxFreeSizeDownload: family?.maxFreeSizeDownload,
        hasGlobalColors: family?.hasGlobalColors,
        metaTitle: family?.metaTitle,
        metaDescription: family?.metaDescription,
        slug: family?.slug,
        strokeAllowed: family?.strokeAllowed,
        defaultStrokeWidth: family?.defaultStrokeWidth,
        visibleOnlyOnFreebiesTab: family ? family?.visibleOnlyOnFreebiesTab : false,
        paymentLink: family?.paymentLink || null,
        sendOwlFileId: family?.sendOwlFileId || null,
        familyGroupHash: family?.familyGroupHash || null,
        style: family?.style || null,
        displayInFreeAndPremium: family?.displayInFreeAndPremium || false,
        authorName: family?.authorName,
        authorLink: family?.authorLink,
        licenseName: family?.licenseName,
        licenseLink: family?.licenseLink,
        isThirdParty: family?.isThirdParty,
        relatedFamilyHash: family?.relatedFamilyHash,
      }}
    >
      <DrawerTwoColumns
        leftCol={
          <FamilyFormLeftColumn form={form} family={family} imagePublicId={family?.cover || ''} />
        }
        rightCol={<FamilyFormRightColumn form={form} />}
      />
    </Form>
  )
}

export default FamilyForm
